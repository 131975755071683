import { Container, Heading, Text } from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import NextLink from "next/link";

import HomeLayout from "components/layouts/HomeLayout";
import paths from "paths";
import type { Page } from "types/page";

const PageNotFound: Page = () => {
  return (
    <>
      <NextSeo title="404: Page Not Found" description="Page not found" />
      <Container>
        <Heading data-cy="404-heading">Page Not Found</Heading>
        <Text data-cy="404-message">
          (Error 404) The page requested was not found. Please check your URL,
          or{" "}
          <NextLink href={paths.home}>
            <a data-cy="return-link">click to return home</a>
          </NextLink>
        </Text>
      </Container>
    </>
  );
};

PageNotFound.Layout = HomeLayout;
export default PageNotFound;
